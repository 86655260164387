a {
  /* color: #eca013; */
  color: #61dafb;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginHeader {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.nav {
  background-color: #282c34;
  padding-top: 10px;
}

.nav-col {
  width: 33.3%;
  text-align: center;
}

.navTable {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  font-size: 1.5em;
  width: 100%;
  display: flex;
}

/* .potatoObservationExplorerHeader {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.fake-link {
  color: grey;
  text-decoration: underline;
  cursor: pointer;
  background-color: inherit;
  border: none;
}
.observationListTable {
  font-size: 12px;
  width: 100%;
}

.activityListTable {
  font-size: 18px;
  width: 100%;
}

.entity-table {
  margin: auto;
  text-align: left;
}

.activity-table {
  margin: auto;
  text-align: left; 
}

.traceroot-table {
  margin: auto;
  text-align: left;
}

.entity-table .copy-col {
  width: 50px;
}

/* .entity-table .traceroot-col {
  width: 50px;
} */

.entity-table .timestamp-col {
  /* width: 50px; */
}

.entity-table .data-col {
  /* width: 50%; */
}

.react-json-view {
  min-width: 400px;
}

.inputs {
  display: flex;
  margin: 24px;
}

.inputs .or {
  margin: 40px;
}

.inputs label {
  margin-right: 8px;
}

.query-button {
  font-size: 24px;
}

.showing {
  margin: 24px;
}

/* .entity-table .diff-col {
  width: 20px;
} */

.customer-form-inputs {
  display: flex;
  margin: 24px;
  justify-content: center;
}

.customer-inputs-left {
  order: 1;
  margin-right: 10px;
  display: block;
}

.customer-inputs-right {
  order: 2;
  margin-left: 10px;
  display: block;
}